import { createApp } from "vue";
import { createWebHistory, createRouter } from "vue-router";
import { createPinia } from 'pinia'
import { useUserInfoStore } from '@/UserInfoStore'
import App from "./App.vue";

import HomeView from "./HomeView.vue";
import MomentsList from "./MomentsList.vue";
import MomentsShow from "./MomentsShow.vue";
import LoginView from "./LoginView.vue";
import SettingsView from "./SettingsView.vue";
import NotFound from "@/components/NotFound.vue";

const pinia = createPinia();
const store = useUserInfoStore(pinia)
store.init()

const routes = [
  { path: "/", component: HomeView },
  { path: "/login", component: LoginView },
  { path: "/logout", component: HomeView,
     beforeEnter: (to, from, next) => {
       store.logout();
       router.push( '/' );
       next();
    },
  },
  { path: "/moments/:id", component: MomentsShow,
      meta: { requiresAuth: true }
  },
  { path: "/moments", component: MomentsList,
      meta: { requiresAuth: true }
  },
  { path: "/settings", component: SettingsView,
      meta: { requiresAuth: true }
  },
  {
    path: "/:pathMatch(.*)", component: NotFound,
      meta: { requiresAuth: true }
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
 if (to.meta.requiresAuth) {
   const token = localStorage.getItem('token');
   if (token) {
     next(); // User is authenticated, proceed to the route
   } else {
     next('/login'); // User is not authenticated, redirect to login
   }
 } else { // Non-protected route, allow access
   next();
 }
});

const app = createApp(App).use(router).use(pinia);
app.mount("#injection_point");
