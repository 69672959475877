export function cleanUpStorage() {
  localStorage.removeItem('token');
  localStorage.removeItem('user-info');
  console.debug( "✌️ ending client-side session" );
}

export function getUserInfo() {
  if( !localStorage.getItem( "user-info" ) )
    // throw new Error("no current user in localStorage; perhaps we're not lopgged in!");
    return undefined;
  else
    // lmao no explicit return
    return JSON.parse( localStorage.getItem( "user-info" ) );
}

export function emailValid(email) {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z0-9]+\.)+[a-zA-Z]{2,}))$/
    );
}

export function passwordValid(password) {
  if( !password || password.length < 8 )
    return false;
  return true;
}

