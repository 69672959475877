import { defineStore } from 'pinia'
import { Token } from "./models/Token";
import { cleanUpStorage } from "./helpers/LoginHelpers";

export const useUserInfoStore = defineStore('userInfo', {
  state: () => ({
    user: null,
    isLoggedIn: false,
  }),
  actions: {
    init() {
      const untrusted_user_info = localStorage.getItem( "user-info" );
      let untrusted_user_info_json = '';
      try {
        untrusted_user_info_json = JSON.parse( untrusted_user_info );
        const token = localStorage.getItem( "token" );
        if( parseInt(untrusted_user_info_json.id) > 0 && new Token(token).valid())
          this.login(untrusted_user_info_json);
        else
          this.logout(); // clean up
      } catch(err) {
        console.log( "failure parsing untrusted_user_info, we're not logged in" );
        this.logout(); // clean up
      }

    },
    login(userData) {
      this.user = userData;
      this.isLoggedIn = true;
    },
    logout() {
      this.user = null;
      this.isLoggedIn = false;
      cleanUpStorage();
    },
  },
});

