<script setup>
import { ref } from "vue";
import { useRouter } from 'vue-router'

import { Token } from "./models/Token";
import { emailValid, passwordValid } from "./helpers/LoginHelpers";
import { useUserInfoStore } from '@/UserInfoStore'
import ApiFactory from "@/api";

import HeadlineText from "./components/HeadlineText.vue";

const store = useUserInfoStore();

const email = ref("");
const password = ref("");
const router = useRouter()

async function performLogin(email, password) {
  const api = ApiFactory();
  try {
    return await api.login(email, password);
  } catch (err) {
    console.log( err.message ); // FIXME handle errors
  }
}

const submit_login_form = function () {
  if( !passwordValid(password.value) || !emailValid(email.value)) {
    console.log( "FIXME: signal in the UI with some validation that the form is not complete");
    return false;
  }

  performLogin(email.value, password.value).then (response => {
    if (response.error == "Invalid credentials") {
      console.log("LOGIN FAILED; FIXME: report this in the UI");
    } else if (response.error == "Incomplete params") {
      console.log("CHECK FOR FAILED LOGIN or MISSING PARAMS");
    } else { /* SUCCESS */
      const token = new Token(response.token);
      if( ! token.valid() ) {
        console.log("FIXME: HANDLE INVALID TOKEN: ", token.value());
      } else {
        console.debug("🔑 persisting token" );
        localStorage.setItem('user-info', JSON.stringify(response.user));
        localStorage.setItem('token', token.value());

        store.login(response.user.user_name);
        router.push( '/moments' );
      }
    }
  });
};
</script>

<template>

  <HeadlineText
    headline="🧴 Flourishing, moisturized..."
    sub_head="Hell, I *am* the dang login view!"
  />

  <form class="flex items-middle">
    <div class="w-full max-w-sm min-w-[200px]">
      <label for="email" class="block mt-3 ml-3">Email address</label>
      <div class="relative">
        <input
          v-model="email"
          type="text"
          name="email"
          id="email"
          required
          minlength="3"
          maxlength="20"
          size="20"
          placeholder="Raving George"
          class="w-full pl-3 pr-10 py-2 font-bold

          border-slate-200 
          dark:bg-zinc-800
          dark:border-slate-700 
          dark:placeholder:text-slate-600 
          dark:text-white
          focus:border-slate-400 
          hover:border-slate-300 
          placeholder:text-slate-400 
          text-slate-600 text-sm border 

          rounded-lg transition duration-300 ease focus:outline-none shadow-sm focus:shadow 
          "
        />

        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
          class="absolute w-5 h-5 top-2 right-2.5 text-slate-600"
        >
          <path
            d="M1.5 8.67v8.58a3 3 0 0 0 3 3h15a3 3 0 0 0 3-3V8.67l-8.928 5.493a3 3 0 0 1-3.144 0L1.5 8.67Z"
          />
          <path
            d="M22.5 6.908V6.75a3 3 0 0 0-3-3h-15a3 3 0 0 0-3 3v.158l9.714 5.978a1.5 1.5 0 0 0 1.572 0L22.5 6.908Z"
          />
        </svg>
      </div>

      <label for="password" class="block mt-5 ml-3">Password</label>
      <div class="relative">
        <input
          type="password"
          v-model="password"
          id="password"
          required
          minlength="8"
          maxlength="128"
          size="30"

          class="w-full pl-3 pr-10 py-2 mb-4 
            text-sm border rounded-lg transition duration-300 ease shadow-sm focus:shadow

          border-slate-200 
          dark:bg-zinc-800
          dark:border-slate-700 
          dark:placeholder:text-slate-200 
          dark:text-white
          focus:border-slate-400 
          focus:outline-none 
          hover:border-slate-300 
          placeholder:text-slate-400 
          text-slate-600 text-sm border 

"
        />
        <!-- class="block w-72 p-2 mt-1 mb-3 rounded-lg"  -->

        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
          class="absolute w-5 h-5 top-2 right-2.5 text-slate-600"
        >
          <path
            fill-rule="evenodd"
            d="M12 1.5a5.25 5.25 0 0 0-5.25 5.25v3a3 3 0 0 0-3 3v6.75a3 3 0 0 0 3 3h10.5a3 3 0 0 0 3-3v-6.75a3 3 0 0 0-3-3v-3c0-2.9-2.35-5.25-5.25-5.25Zm3.75 8.25v-3a3.75 3.75 0 1 0-7.5 0v3h7.5Z"
            clip-rule="evenodd"
          />
        </svg>
      </div>

      <button
        @click.prevent="submit_login_form()"
        class="rounded-lg bg-orange-600 px-6 py-2"
      >
        Sign in
      </button>
    </div>
  </form>
</template>
