<script setup>
import { ref } from "vue";
import { onMounted } from "vue";
import HeadlineText from "@/components/HeadlineText";
import MomentCard from "@/components/MomentCard";
import ApiFactory from "@/api";

const moments = ref([]);

async function fetchMoments() {
  const api = ApiFactory();
  try {
    return await api.getMoments();
  } catch (err) {
    console.log( err.message ); // FIXME handle errors
  }
}

onMounted(() => {
  fetchMoments().then (response => {
    moments.value.splice(0, 0, ...response.moments); // destructure!
    console.debug("loaded", response.moments.length, "moments");
  });
});
</script>

<template>
  <HeadlineText headline="Moments" />
  <ul>
    <li v-for="(m, i) in moments" :key="i">
      <MomentCard :moment="m" />
    </li>
  </ul>
</template>
