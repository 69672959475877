const BASE_URL = `${process.env.VUE_APP_API}/api/v1`;

const apiFetch = async (endpoint, options = {}) => {
  const url = `${BASE_URL}${endpoint}`;
  const token = localStorage.getItem( "token" );
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${token}`,
    ...options.headers,
  };

  const response = await fetch(url, {
    ...options,
    headers,
  });

  if (!response.ok) {
    throw new Error(`API error: ${response.statusText}`); // FIXME handle errors
  }
  return response.json();
};

const ApiFactory = () => ({
  getMoments() {
    return apiFetch('/moments');
  },

  getMoment(id) {
    return apiFetch(`/moments/${id}`);
  },

  getUser(id) {
    return apiFetch(`/users/${id}`);
  },

  login(email, password) {
    return apiFetch(`/login/`, {
      method: 'POST', 
      body: JSON.stringify({email: email, password: password})
    });
  },
});

export default ApiFactory;
