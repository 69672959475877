<script setup>
import { ref } from "vue";
import { onMounted } from "vue"
import { useRoute } from "vue-router";
import HeadlineText from "@/components/HeadlineText";
import MomentCard from "@/components/MomentCard";
import ApiFactory from "@/api";

const route = useRoute();
const moment = ref([]);

async function fetchMoment(id) {
  const api = ApiFactory();
  try {
    return await api.getMoment(id);
  } catch (err) {
    console.log( err.message ); // FIXME handle errors
  }
}

onMounted(() => {
  fetchMoment(route.params.id).then (response => {
    moment.value = response.moment;
    console.debug( "loaded Moment: ", moment.value.text );
  });
});
</script>

<template>
  <HeadlineText headline="Moment Details" />
  <MomentCard :moment="moment" />
</template>
